import { message } from "antd";
import pageRoutes from "../../routes";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pipGetUserAccessToken } from "../../utils/pip";
import DateHourInput from "../../component/DateHourInput";
import {
  fetchAddress,
  fetchLatLong,
  createScheduleRide,
} from "../../redux/action/rideActions";
import CarLoader from "../../Components/carLoader";

const ScheduleRide = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = pipGetUserAccessToken();
  const [pickupDateTime, setPickupDateTime] = useState();
  const { isLoading, addresses } = useSelector((state) => state.rideReducer);
  const [pickupLocation, setPickupLocation] = useState("");
  const [isPickupDropdown, setIsPickupDropdown] = useState(false);

  const [pickupLatLong, setPickupLatLong] = useState({});
  const [droplatLong, setDropLatLong] = useState({});

  const [dropLocation, setDropLocation] = useState("");
  const [isDropDropdown, setIsDropLocationDropdown] = useState(false);

  useEffect(() => {
    const savedRideData = JSON.parse(
      localStorage.getItem("scheduleRideDetailData")
    );
    if (savedRideData) {
      setPickupLocation(savedRideData.start);
      setDropLocation(savedRideData.destination);
      setPickupDateTime(`${savedRideData.dateOfBooking} ${savedRideData.time}`);
      setPickupLatLong({
        lat: savedRideData.startLatitude,
        lng: savedRideData.startLongitude,
      });
      setDropLatLong({
        lat: savedRideData.destLatitude,
        lng: savedRideData.destLongitude,
      });

      localStorage.removeItem("scheduleRideDetailData"); // Clear after autofill
    }
  }, []);

  // get pickup-location-address
  const handlePickupLocationChange = (val) => {
    if (!pickupLocation) {
      return;
    }
    const callback = (response) => {
      if (response?.success);
      {
        setIsPickupDropdown(true);
      }
    };
    dispatch(fetchAddress({ payload: { val }, callback }));
  };

  // get drop-location-address
  const handleDropLocationChange = (val) => {
    if (!dropLocation) {
      return;
    }
    const callback = (response) => {
      if (response?.success);
      {
        setIsDropLocationDropdown(true);
      }
    };
    dispatch(fetchAddress({ payload: { val }, callback }));
  };

  // get pickup-location-lat-long
  const handlePickupLatLong = (address) => {
    setPickupLocation(address);
    const callback = (response) => {
      if (response?.success) {
        setPickupLatLong(response?.data);
        setIsPickupDropdown(false);
      }
    };
    dispatch(fetchLatLong({ payload: { address }, callback }));
  };

  // get drop-location-lat-long
  const handleDropLatLong = (address) => {
    setDropLocation(address);
    const callback = (response) => {
      if (response?.success) {
        setDropLatLong(response?.data);
        setIsDropLocationDropdown(false);
      }
    };
    dispatch(fetchLatLong({ payload: { address }, callback }));
  };

  const handleScheduleRide = () => {
    if (!pickupLocation) {
      message.error("Please select pickup location");
      return;
    }
    if (!dropLocation) {
      message.error("Please select drop-off location");
      return;
    }
    if (!pickupDateTime) {
      message.error("Please select pickup date and time");
      return;
    }
    const data = {
      start: pickupLocation,
      start_2: pickupLocation,
      destination: dropLocation,
      destination_1: dropLocation,
      dateOfBooking: pickupDateTime?.split(" ")[0],
      time: pickupDateTime?.split(" ")[1],
      startLatitude: `${pickupLatLong?.lat}`,
      startLongitude: `${pickupLatLong?.lng}`,
      destLatitude: `${droplatLong?.lat}`,
      destLongitude: `${droplatLong?.lng}`,
      distance: "distance",
      duration: "duration",
      mapCoordinates: "mapCoordinates",
    };

    if (pipGetUserAccessToken()) {
      // User is logged in, proceed with API call
      const callback = (response) => {
        if (response?.success) {
          setPickupLocation("");
          setDropLocation("");
          setPickupDateTime();
          setPickupLatLong("");
          setDropLatLong("");
        }
      };
      dispatch(createScheduleRide({ payload: data, callback }));
    } else {
      // User is not logged in, save data & redirect to login
      localStorage.setItem("scheduleRideDetailData", JSON.stringify(data));
      navigate(pageRoutes?.userLogin);
    }
  };

  if (isLoading) {
    return <CarLoader />;
  }

  return (
    <>
      <div className="cb_py_60" id="home">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-4 mb-lg-0">
              <div className="ct_hero_title">
                <h2 className="cb_fs_52 ct_fw_600 mb-3">
                  Go anywhere with Cabty
                </h2>
                <div className="cb_nav_tab">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="" role="presentation">
                      <button
                        className=" active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        <div className="cb_tab_icon">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="m20.9 9-1.5-4.6c-.3-.8-1-1.4-1.9-1.4H6.4c-.9 0-1.6.5-1.9 1.4L3 9H1v3h1v9h4v-2h12v2h4v-9h1V9h-2.1ZM5 14h4v2H5v-2Zm10 2v-2h4v2h-4ZM7.1 6h9.7l1.3 4H5.8l1.3-4Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                        <span>Ride</span>
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <form className="cb_location_form mt-5">
                        <div className="cb_decoration_line">
                          <div className="position-relative mb-3">
                            <div className="cb_input_icon_2">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                data-baseweb="icon"
                              >
                                <title>search</title>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <input
                              onChange={(e) => {
                                handlePickupLocationChange(e.target.value);
                                setPickupLocation(e.target.value);
                              }}
                              value={pickupLocation}
                              type="text"
                              name="Pickup"
                              placeholder="Pickup location"
                              className="form-control ct_input cb_text_indent_30 ct_input_grey"
                            />
                            {/* pickup location search address  */}
                            {addresses?.length > 0 && (
                              <div
                                className={`cb_location_dropdown ${
                                  isPickupDropdown ? "" : "d-none"
                                }`}
                              >
                                <ul>
                                  {addresses?.map((item, index) => (
                                    <li
                                      onClick={() => {
                                        handlePickupLatLong(item?.description);
                                      }}
                                    >
                                      <div className="cb_city_icon">
                                        <svg
                                          width="1em"
                                          height="1em"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <title>Location marker</title>
                                          <path
                                            d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </div>
                                      <div className="cb_location_name_title">
                                        <h5 className="ct_fs_18 ct_fw_600">
                                          {item?.description}
                                        </h5>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>

                          <div className="position-relative mb-3">
                            <div className="cb_input_icon_2">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                data-baseweb="icon"
                              >
                                <title>search</title>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 2H2v20h20V2Zm-7 7H9v6h6V9Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <input
                              onChange={(e) => {
                                handleDropLocationChange(e.target.value);
                                setDropLocation(e.target.value);
                              }}
                              value={dropLocation}
                              type="text"
                              name="Pickup"
                              placeholder="Dropoff location"
                              className="form-control ct_input cb_text_indent_30 ct_input_grey"
                            />
                            {/* drop location search address  */}
                            {addresses?.length > 0 && (
                              <div
                                className={`cb_location_dropdown ${
                                  isDropDropdown ? "" : "d-none"
                                }`}
                              >
                                <ul>
                                  {addresses?.map((item, index) => (
                                    <li
                                      onClick={() => {
                                        handleDropLatLong(item?.description);
                                      }}
                                    >
                                      <div className="cb_city_icon">
                                        <svg
                                          width="1em"
                                          height="1em"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <title>Location marker</title>
                                          <path
                                            d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </div>
                                      <div className="cb_location_name_title">
                                        <h5 className="ct_fs_18 ct_fw_600">
                                          {item?.description}
                                        </h5>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="position-relative  mb-3">
                              <div className="cb_input_icon_2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <title>Calendar</title>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M23 8V4h-3V1h-3v3H7V1H4v3H1v4h22Zm0 15H1V10h22v13ZM8 14H5v3h3v-3Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </div>
                              <DateHourInput
                                value={pickupDateTime}
                                onChange={(date) => {
                                  setPickupDateTime(date?.format());
                                }}
                                minDate={new Date()}
                                placeholder="--  Pick-up Date / Time --"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mt-3">
                          {token ? (
                            <button
                              type="button"
                              className="ct_submit_btn ms-0"
                              onClick={() => handleScheduleRide()}
                            >
                              Schedule ride
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="ct_submit_btn ms-0 w-auto px-4"
                              onClick={() => {
                                handleScheduleRide();
                                // navigate(pageRoutes?.userLogin)
                              }}
                            >
                              Schedule ride
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Map */}
            <div className="col-lg-6 mb-4 mb-lg-0  offset-lg-1">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.0474390517!2d-74.31001693837437!3d40.69753800715056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1733736350395!5m2!1sen!2sin"
                  width="100%"
                  height="500"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleRide;
