const pageRoutes = {
  home: "/",
  safety: "/safty",
  dashboard: "/admin",
  login: "/admin-login",
  rides: "/admin-rides",
  rides1: "/admin-rides1",
  settings: "/admin-settings",
  userRide: "/admin-userride",
  users: "/admin-users",
  changePassword: "/admin-changepassword",
  forgetPassword: "/admin-forgetpassword",
  cabs: "/admin-cabs",
  bookRide: "/book_ride",
  otpVerification: "/admin-otp-verification",
  userProfile: "/admin-user-profile",
  allBooking: "/admin-all-booking",
  location: "/admin-location",
  support: "/admin-support",
  category: "/admin-category",
  reAssign: "/admin-reassign",
  emergencyNotification: "/admin-emergency-notification",
  complaint: "/complaint",
  supportOther: "/support",
  userLogin: "/user-signin",
  userSignup: "/user-signup",
};

export default pageRoutes;
