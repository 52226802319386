import * as Yup from "yup";

// Sign-Up
export const userSignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter email ")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
      "Please enter a valid email"
    ),
  full_name: Yup.string()
    .trim()
    .required("Please enter full name")
    .min(3, "Full name must be at least 3 characters long")
    .max(20, "Full name cannot be more than 20 characters long")
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "Name can only contain alphanumeric characters and spaces"
    ),
  password: Yup.string()
    .required("Please enter your password")
    .min(8, "Password cannot be less then 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%&'*+-.,:;<=>?^_`{|}~])/,
      "Strong passwords require at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character."
    ),
  confirmPassword: Yup.string()
    .required("Please enter confirm password")
    .oneOf([Yup.ref("password"), null], "Your password must match"),
});

// Sign-In
export const userLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email ")
    .required("Please enter email ")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
      "Please enter a valid email "
    ),
  password: Yup.string()
    .required("Please enter password")
    .min(8, "Password cannot be less then 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%&'*+-.,:;<=>?^_`{|}~])/,
      "Strong passwords require at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character."
    ),
});
