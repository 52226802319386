import "./style.css";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { message } from "antd";
import pageRoutes from "../routes";
import React, { useState } from "react";
import ScheduleRide from "./ScheduleRide";
import { useNavigate } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SimpleReactValidator from "simple-react-validator";
import { baseUrl, supportAPI } from "../Components/Config";

const Home = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comment: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [validator] = useState(
    new SimpleReactValidator({
      className: "text-danger",
    })
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      setIsLoading(true);
      axios({
        method: "post",
        url: baseUrl + supportAPI,
        data: formData,
      })
        .then((res) => {
          if (res?.data?.success) {
            message.success(res?.data?.message);
            setFormData({
              name: "",
              email: "",
              comment: "",
            });
            validator.hideMessages();
          } else {
            message.error(res?.data?.message);
          }
        })
        .catch((error) => {
          message.error("Something went wrong, please try again later");
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      validator.showMessages();
    }
  };

  const options = {
    loop: true,
    margin: 0,
    nav: true,
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <Header />
      {/* Schedule Ride Form & Map */}
      <ScheduleRide />

      <section className="ct_sec_padd" id="service">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="ct_fs_35 ct_fw_600 mb-5">Services</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="cb_suggestion_card_grey">
                <div>
                  <h5 className="ct_fs_16 mb-2">Ride</h5>
                  <p className="mb-2 ct_fs_14">
                    Go anywhere with Cabty. Request a ride, hop in, and go.
                  </p>
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(pageRoutes?.login)}
                    className="cb_grey_btn mt-3 d-inline-block "
                  >
                    Details
                  </a>
                </div>
                <div className="cb_suggestion_img">
                  <img src="img/ride1.png" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="cb_suggestion_card_grey">
                <div>
                  <h5 className="ct_fs_16 mb-2">Reserve</h5>
                  <p className="mb-2 ct_fs_14">
                    Reserve your ride in advance so you can relax on the day of
                    your trip.
                  </p>
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(pageRoutes?.login)}
                    className="cb_grey_btn mt-3 d-inline-block "
                  >
                    Details
                  </a>
                </div>
                <div className="cb_suggestion_img">
                  <img src="img/reserve_clock1.png" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="cb_suggestion_card_grey">
                <div>
                  <h5 className="ct_fs_16 mb-2">Package</h5>
                  <p className="mb-2 ct_fs_14">
                    Cabty Connect makes same-day delivery easier than ever.
                  </p>
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(pageRoutes?.login)}
                    className="cb_grey_btn d-inline-block mt-3"
                  >
                    Details
                  </a>
                </div>
                <div className="cb_suggestion_img">
                  <img src="img/parcel.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Service Section S */}
      <section  id="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 mb-4 mb-md-0">
              <div className="cb_drive_img">
                <img src="img/about_7_1.jpg" />
              </div>
            </div>
            <div className="col-md-6 offset-md-1 mb-4 mb-md-0">
              <div>
                <h1 className="cb_fs_52 ct_fw_600 mb-5">
                  Drive on your terms, earn what you need.
                </h1>
                <p className="mb-0">
                  Make money whenever it fits your schedule, with deliveries,
                  rides, or both. Use your own car or rent one through Cabty.
                </p>
                <div className="d-flex align-items-center gap-3 flex-wrap mt-4">
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(pageRoutes?.userSignup)}
                    className="ct_submit_btn mx-0 d-flex align-items-center justify-content-center"
                  >
                    Get started
                  </a>
                  {/* <a
                    href="javascript:void(0)"
                    onClick={() => navigate(pageRoutes?.userLogin)}
                    className="ct_submit_btn ct_"
                  >
                     Sign In
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ct_sec_padd pn-0" id="howtouse">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="ct_fs_35 ct_fw_600 mb-2">
                How to use the Cabty app
              </h1>
              <p className="mb-5">
                Our core service is developing technology that connects drivers
                and riders on demand. Here’s how the app works, step by step:
              </p>
            </div>
          </div>
          <div className="row ct_res_relative">
            <div className="col-md-4 pb-4">
              <div className="cb_step_img">
                <img src="img/safty_cab.jpg" className="w-100" />
              </div>
            </div>
            <div className="col-md-7 pb-4 offset-md-1">
              <div className="cb_how_work_cnt_2">
                <h6 className="mb-3">Step 1 </h6>
                <h4 className="mb-3">A rider opens the app</h4>
                <p className="mb-0">
                  The rider enters their destination into the “Where to?” box;
                  reviews each ride option for vehicle size, price, and
                  estimated dropoff time; chooses the desired option; then
                  confirms the pickup.
                </p>
              </div>
            </div>
          </div>
          <div className="row ct_res_relative">
            <div className="col-md-4 pb-4">
              <div className="cb_step_img">
                <img src="img/Safety_LP-Rider-RiderTips_2x.webp" className="w-100" />
              </div>
            </div>
            <div className="col-md-7 pb-4 offset-md-1">
              <div className="cb_how_work_cnt_2">
                <h6 className="mb-3">Step 2 </h6>
                <h4 className="mb-3">The rider is matched with a driver</h4>
                <p className="mb-0">
                  A nearby driver sees and chooses to accept the rider’s ride
                  request. The rider is automatically notified when the driver’s
                  vehicle is about a minute away.
                </p>
              </div>
            </div>
          </div>
          <div className="row ct_res_relative">
            <div className="col-md-4 pb-4">
              <div className="cb_step_img">
                <img src="img/Safety_LP-Rider-CommGuides_2x.webp" className="w-100" />
              </div>
            </div>
            <div className="col-md-7 pb-4 offset-md-1">
              <div className="cb_how_work_cnt_2">
                <h6 className="mb-3">Step 3</h6>
                <h4 className="mb-3">The driver picks up the rider</h4>
                <p className="mb-0">
                  The driver and the rider verify each other’s names and the
                  destination. Then the driver starts the ride.
                </p>
              </div>
            </div>
          </div>
          <div className="row ct_res_relative">
            <div className="col-md-4 pb-4">
              <div className="cb_step_img">
                <img src="img/hero_bg_1.jpg" className="w-100" />
              </div>
            </div>
            <div className="col-md-7 pb-4 offset-md-1">
              <div className="cb_how_work_cnt_2">
                <h6 className="mb-3">Step 4</h6>
                <h4 className="mb-3">
                  The driver takes the rider to the destination
                </h4>
                <p className="mb-0">
                  The app gives the driver the option to access turn-by-turn
                  directions.
                </p>
              </div>
            </div>
          </div>
          <div className="row ct_res_relative">
            <div className="col-md-4 pb-4">
              <div className="cb_step_img">
                <img src="img/safty_cab.jpg" className="w-100" />
              </div>
            </div>
            <div className="col-md-7 pb-4 offset-md-1">
              <div className="cb_how_work_cnt_2">
                <h6 className="mb-3">Step 5</h6>
                <h4 className="mb-3">
                  The driver and rider leave ratings and reviews
                </h4>
                <p className="mb-0">
                  At the end of each trip, drivers and riders can rate each
                  other from 1 to 5 stars. Riders also have the option to give
                  the driver compliments and a tip directly in the app.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="ct_sec_padd"
        id="download"
      
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_app_download_bg">
                <div className="row align-items-center">
                  <div className="col-md-7 mb-4 mb-md-0">
                    {/* <h1 className="ct_about_sub_head">
                      DOWNLOAD CABTY APP NOW
                    </h1> */}

                    <h2 className="ct_h2_head mb-5">It’s easier in the apps</h2>
                    <div>
                      <h6 className="mb-0 ct_fs_20 ct_fw_500">
                        Click here for User App
                      </h6>
                      <div className="ct_dowload_apps mt-0 justify-content-start mt-2">
                        <a
                          href="https://apps.apple.com/us/app/cabty/id6523432655"
                          target="_blank"
                        >
                          <img src="img/appstore.png" alt="" />
                        </a>

                        <a
                          href="https://play.google.com/store/apps/details?id=com.qr_cab&pcampaignid=web_share"
                          target="_blank"
                        >
                          <img src="img/google-play.png" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="mt-3">
                      <h6 className="mb-0 ct_fs_20 ct_fw_500">
                        Click here for Driver App
                      </h6>
                      <div className="ct_dowload_apps mt-0 justify-content-start mt-2">
                        <a
                          href="https://apps.apple.com/us/app/cabty-driver/id6523432144"
                          target="_blank"
                        >
                          <img src="img/appstore.png" alt="" />
                        </a>

                        <a
                          href="https://play.google.com/store/apps/details?id=com.qr_cab_driver&pcampaignid=web_share"
                          target="_blank"
                        >
                          <img src="img/google-play.png" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5 mb-4 mb-md-0">
                    <div className="ct_app_store_12">
                      <img src="img/phone_1-1-1.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="ct_footer_car_track">
        <img src="img/taxi_4.png" alt="" />

        <div
          className="ct_footer_car_trackimg"
          style={{ backgroundImage: "url(img/border_1.png)" }}
        ></div>
      </div>

      <Footer />
    </>
  );
};

export default Home;