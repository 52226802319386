import authReducer from "./reducers/authReducer";
import rideReducer from "./reducers/rideReducer";
import settingReducer from "./reducers/settingReducer";

import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    authReducer,
    settingReducer,
    rideReducer,
  },
});

export default store;
