// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Provider } from 'react-redux';
// import store from './redux/store';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <Provider store={store}>
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
//   </Provider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./App.css";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import store from "./redux/store";
import pageRoutes from "./routes";
import Setting from "./Setting";
import UserRide from "./Components/UserRide";
import Users from "./Components/Users";
import ChangePassword from "./Components/ChangePassword";
import Login from "./Components/Login";
import ForgetPassword from "./Components/ForgetPassword";
import Dashboard from "./Components/Dashboard";
import Cabs from "./Components/Cabs";
import BookRides from "./Components/web_user/BookRides";
import VerifyRide from "./Components/web_user/VerifyRide";
import UserProfile from "./Components/web_user/UserProfile";
import AllBooking from "./Components/web_user/AllBooking";
import Location from "./Components/Location";
import Support from "./Support";
import Category from "./Components/Category";
import Home from "./home";
import Safety from "./home/Safety";
import ReAssign from "./Setting/ReAssign";
import Complaint from "./Setting/Complaint";
import EmergencyNotification from "./Setting/EmergencyNotification";
import SupportOther from "./Support/SupportOther";
import Rides from "./pages/Rides";
import UserLogin from "./pages/user authenticate/UserLogin";
import UserSignUp from "./pages/user authenticate/UserSignUp";

const router = createBrowserRouter(
  [
    { path: pageRoutes?.home, element: <Home /> },
    { path: pageRoutes?.safety, element: <Safety /> },
    { path: pageRoutes?.dashboard, element: <Dashboard /> },
    { path: pageRoutes?.login, element: <Login /> },
    { path: pageRoutes?.rides1, element: <Rides /> },
    { path: pageRoutes?.settings, element: <Setting /> },
    { path: pageRoutes?.userRide, element: <UserRide /> },
    { path: pageRoutes?.users, element: <Users /> },
    { path: pageRoutes?.changePassword, element: <ChangePassword /> },
    { path: pageRoutes?.forgetPassword, element: <ForgetPassword /> },
    { path: pageRoutes?.cabs, element: <Cabs /> },
    { path: pageRoutes?.bookRide, element: <BookRides /> },
    { path: pageRoutes?.otpVerification, element: <VerifyRide /> },
    { path: pageRoutes?.userProfile, element: <UserProfile /> },
    { path: pageRoutes?.allBooking, element: <AllBooking /> },
    { path: pageRoutes?.location, element: <Location /> },
    { path: pageRoutes?.support, element: <Support /> },
    { path: pageRoutes?.category, element: <Category /> },
    { path: pageRoutes?.reAssign, element: <ReAssign /> },
    {
      path: pageRoutes?.emergencyNotification,
      element: <EmergencyNotification />,
    },
    { path: pageRoutes?.complaint, element: <Complaint /> },
    { path: pageRoutes?.supportOther, element: <SupportOther /> },
    { path: pageRoutes?.userLogin, element: <UserLogin /> },
    { path: pageRoutes?.userSignup, element: <UserSignUp /> },
  ],
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true,
    },
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
