import { useEffect } from "react";
import pageRoutes from "../routes";
import SubNavbar from "./SubNavbar";
import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import { useNavigate } from "react-router-dom";
import { pipGetAccessToken } from "../utils/pip";

function Complaint() {
  const data = ["", "", "", "", "", ""];
  const navigate = useNavigate();

  useEffect(() => {
    const token = pipGetAccessToken()
    if (token == null) {
      navigate(pageRoutes?.login);
    } else {
    }
  }, []);

  return (
    <>
      <section className="ct_padd_in_100">
        <Navbar />

        <div className="container-fluid">
          <SubNavbar />

          <div className="ct_location_table table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>User Name</th>
                  <th>Customer Complaint</th>
                  <th>Driver Name</th>
                  <th>Car No.</th>
                  <th>Car Modal</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <div className="ct_user_img_circle  d-flex align-items-center gap-2">
                        <img src="img/Group_1634.png" alt="" />
                        <h6 className="mb-0">John Doe</h6>
                      </div>
                    </td>
                    <td>
                      <p className="mb-0 ct_description_text_area">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                    </td>
                    <td>Adney</td>
                    <td>EH23 LSG</td>
                    <td>Nishaan</td>
                    <td>
                      <select className="form-control">
                        <option>Pending</option>
                        <option>Resolved</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Complaint;
