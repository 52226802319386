import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./style.css"
const Safety = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
  return (
    <>
      <Header data="safty"/>

      {/* Inner Banner section S */}

      <section className="ct_inner_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="ct_hero_slider_bnr_cnt text-start">
                <h1 className="text-start">
                  Safety for all means <br /> looking out for our riders.
                </h1>

                <p className="text-start mb-0">
                  At Cabty, we believe in a community where everyone belongs.
                  We’ll always treat you with respect and prioritize your safety
                  by upholding high standards from your very first ride. Our
                  proactive safety measures are always active, and we provide
                  real help from real people,{" "}
                </p>

                <h6>anytime, day or night</h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Inner Banner section E */}
      {/* About section S */}

      <section className="ct_sec_padd">
        <div className="container">
          <h4 className="ct_fs_35 text-center mb-5">High safety standards</h4>

          <div className="row align-items-center">
            <div
              className="col-md-6 mb-4 mb-md-0"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="ct_safty_img">
                <img src="img/Safety_LP-Rider-CommGuides_2x.webp" alt="" />
              </div>
            </div>

            <div
              className="col-md-5 offset-md-1 mb-4 mb-md-0"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">1. How we share the ride</h1>

                <p className="ct_para_p">
                  We always treat each other with respect, and we don’t tolerate
                  discrimination. Anyone who violates our policies may be
                  permanently removed from the platform. You can cancel any ride
                  that makes you feel unsafe.
                </p>
              </div>

              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">
                  2. Annual driver background checks
                </h1>

                <p className="ct_para_p">
                  All drivers must pass a background check before driving with
                  Lyft. After that, they're required to pass an annual
                  background check. We also continuously monitor for criminal
                  convictions. And when necessary, we deactivate drivers.
                </p>
              </div>

              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">
                  3. Mandatory driver safety education
                </h1>

                <p className="ct_para_p">
                  All drivers must complete a safety program developed with
                  RAINN, the largest anti-sexual violence organization in North
                  America.
                </p>
              </div>

              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">4. Share your pronouns</h1>

                <p className="ct_para_p">
                  By adding your pronouns to your profile, you can help create a
                  space where everyone feels seen and respected.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ct_sec_padd pt-0 pb-5">
        <div className="container">
          <h4 className="ct_fs_35 text-center mb-5">Proactive safety support</h4>

          <div className="row align-items-center ct_row_reverse">
            <div
              className="col-md-6  mb-4 mb-md-0"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">1. Smart Trip Check-In</h1>

                <p className="ct_para_p">
                  We monitor rides for unusual activity, like long stops or
                  route deviations. If we notice anything off about your ride,
                  we’ll contact you to see if you need help. You can also
                  schedule a check-in to confirm you got to your destination
                  safely.
                </p>
              </div>

              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">2 .Share your location</h1>

                <p className="ct_para_p">
                  Add your trusted contacts to your safety settings. For extra
                  peace of mind, let loved ones see where you are, in real time.
                </p>
              </div>

              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">
                  3. See who you’re riding with
                </h1>

                <p className="ct_para_p">
                  Once you’re matched with your driver, you’ll see their name,
                  profile photo, and rating. And when you add a photo to your
                  profile, drivers know who to look for.
                </p>
              </div>

              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">
                  4. Your privacy is protected
                </h1>

                <p className="ct_para_p">
                  We never share your phone number or personal information with
                  other riders or drivers. And once a trip is complete, your
                  pickup and drop-off locations are concealed in the driver’s
                  trip history.
                </p>
              </div>

              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">5. Two-way ratings</h1>

                <p className="ct_para_p">
                  If you rate your driver three stars or fewer, you won’t be
                  matched with them again. Your feedback is anonymous and lets
                  us take appropriate action to help keep the community safe.
                </p>
              </div>
            </div>

            <div
              className="col-md-6 mb-4 mb-md-0"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <div className="ct_safty_img">
                <img src="img/proactive_safety_app.svg" alt="" className="h-auto" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ct_sec_padd pt-0 pb-5">
        <div className="container">
          <h4 className="ct_fs_35 text-center mb-5">Help from real humans</h4>

          <div className="row align-items-center">
            <div
              className="col-md-6 mb-4 mb-md-0"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="ct_safty_img">
                <img
                  src="img/CONTAINER-Safety_LP-Rider-ADT.gif"
                  alt=""
                  className="h-auto"
                />
              </div>
            </div>

            <div
              className="col-md-6 mb-4 mb-md-0"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">
                  1. Emergency Help with ADT
                </h1>

                <p className="ct_para_p">
                  If you feel unsafe, you can discreetly connect with an ADT
                  security professional from your app. They provide live support
                  in uncomfortable situations. If necessary, they can alert
                  authorities and share important ride details, like your GPS
                  geolocation and the vehicle’s license plate number.
                </p>
              </div>

              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">2. Live safety support</h1>

                <p className="ct_para_p">
                  Safety team members are always standing by, ready to help via
                  phone or chat.
                </p>
              </div>

              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">
                  3. Specialized support and advocacy
                </h1>

                <p className="ct_para_p">
                  Every member of our Safety Team is a credentialed victim
                  advocate. We also have a specialized team to help in specific
                  situations, each member has over 330 hours of training in
                  trauma-informed care.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ct_sec_padd pt-0">
        <div className="container">
          <h4 className="ct_fs_35 text-center mb-5">Looking out for each other</h4>

          <div className="row align-items-center ct_row_reverse">
            <div
              className="col-md-6  mb-4 mb-md-0"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">
                  1. Masks are now optional during rides*
                </h1>

                <p className="ct_para_p">
                  We know that everyone has different comfort levels when it
                  comes to health safety. So you can keep wearing a mask, if
                  you'd like to, when you ride.
                </p>
              </div>

              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head mb-2">
                  2. A respectful community
                </h1>

                <p className="ct_para_p">
                  We expect everyone to follow our Community Guidelines. That
                  includes treating each other with respect, and not riding or
                  driving if you have COVID-19 or any related symptoms.
                </p>
              </div>
            </div>

            <div
              className="col-md-5 offset-md-1 mb-4 mb-md-0"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <div className="ct_safty_img">
                <img src="img/Safety_LP-Rider-MaskOptional_2x.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ct_sec_padd pt-0">
        <div className="container">
          <h4 className="ct_fs_35 text-center mb-5">Rider safety tips</h4>

          <div className="row align-items-center">
            <div
              className="col-md-5 mb-4 mb-md-0"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="ct_safty_img">
                <img src="img/Safety_LP-Rider-RiderTips_2x.webp" alt="" />
              </div>
            </div>

            <div
              className="col-md-6  offset-md-1  mb-4 mb-md-0"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="ct_about_cnt">
                <h2 className="ct_fs_28 ct_fw_600 mb-2">
                  Your 4-point safety check
                </h2>

                <p className="ct_para_p">
                  Before you hop in, make sure you’ve got the right ride.
                </p>

                <ul className="safety_tips_list">
                  <li>
                    <i className="fa-solid fa-circle-check"></i>Check the vehicle
                    make and model
                  </li>

                  <li>
                    <i className="fa-solid fa-circle-check"></i>Confirm the license
                    plate
                  </li>

                  <li>
                    <i className="fa-solid fa-circle-check"></i>Match the driver to
                    the picture in your app
                  </li>

                  <li>
                    <i className="fa-solid fa-circle-check"></i> Ask your driver who
                    they’re here for
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us Section S */}

      <div className="ct_footer_car_track">
        <img src="img/taxi_4.png" alt="" />

        <div
          className="ct_footer_car_trackimg"
          style={{ backgroundImage: "url(img/border_1.png)" }}
        ></div>
      </div>

      <Footer />
    </>
  );
};

export default Safety;
