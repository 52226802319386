import { API_REQUEST } from ".";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pipSetAccessToken, pipSetUserAccessToken } from "../../utils/pip";
import {
  admin_login,
  editProfile,
  forgotPassword,
  get_users_detail,
  password_change,
  sign_up,
  user_login,
} from "../../Components/Config";

// auth-login
export const authLogin = createAsyncThunk("auth-login", async (props) => {
  const { payload, callback } = props;
  try {
    const response = await API_REQUEST({
      url: admin_login,
      method: "POST",
      data: payload,
      isErrorToast: true,
    });
    pipSetAccessToken(response?.JWT_token);
    callback(response);
    return response;
  } catch (error) {
    callback(null, error);
  }
});

// auth-forgot-password
export const authForgotPassword = createAsyncThunk(
  "auth-forgot-password",
  async (props) => {
    const { payload, callback } = props;
    try {
      const response = await API_REQUEST({
        url: forgotPassword,
        method: "POST",
        data: payload,
        isErrorToast: true,
      });
      callback(response);
      return response;
    } catch (error) {
      callback(null, error);
    }
  }
);

// fetch-profile
export const fetchProfile = createAsyncThunk("fetch-profile", async () => {
  try {
    const response = await API_REQUEST({
      url: get_users_detail,
      method: "GET",
    });
    return response;
  } catch (error) {}
});

// auth-change-password
export const changePassword = createAsyncThunk(
  "auth-change-password",
  async (props) => {
    const { payload, callback } = props;
    try {
      const response = await API_REQUEST({
        url: password_change,
        method: "POST",
        data: payload,
      });
      callback(response);
      return response;
    } catch (error) {
      callback(null, error);
    }
  }
);

// update-profile
export const updateProfile = createAsyncThunk(
  "update-profile",
  async (props) => {
    const { payload, callback } = props;
    try {
      const response = await API_REQUEST({
        url: editProfile,
        method: "POST",
        data: payload,
        isErrorToast: true,
      });
      callback(response);
      return response;
    } catch (error) {
      callback(null, error);
    }
  }
);


// user-auth-login
export const userAuthLogin = createAsyncThunk("user-auth-login", async (props) => {
  const { payload, callback } = props;
  try {
    const response = await API_REQUEST({
      url: user_login,
      method: "POST",
      data: payload,
    });
    pipSetUserAccessToken(response?.token);
    callback(response);
    return response;
  } catch (error) {
    callback(null, error);
  }
});

// user-auth-sign-up
export const userAuthSignUp = createAsyncThunk("user-auth-sign-up", async (props) => {
  const { payload, callback } = props;
  try {
    const response = await API_REQUEST({
      url: sign_up,
      method: "POST",
      data: payload,
    });
    callback(response);
    return response;
  } catch (error) {
    callback(null, error);
  }
});