import { API_REQUEST } from ".";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  assign_ride,
  getAllReassignRequestAPI,
  getOnlineDriverAPI,
} from "../../Components/Config";

// fetch-reassign-request
export const fetchReassignRequest = createAsyncThunk(
  "fetch-reassign-request",
  async () => {
    try {
      const response = await API_REQUEST({
        url: getAllReassignRequestAPI,
        method: "GET",
      });
      return response;
    } catch (error) {}
  }
);

// fetch-online-driver-list
export const fetchOnlineDriver = createAsyncThunk(
  "fetch-online-driver-list",
  async () => {
    try {
      const response = await API_REQUEST({
        url: getOnlineDriverAPI,
        method: "GET",
      });
      return response;
    } catch (error) {}
  }
);

// Assign-ride
export const AssignRide = createAsyncThunk("Assign-ride", async (props) => {
  const { payload, callback } = props;
  try {
    const response = await API_REQUEST({
      url: assign_ride,
      method: "POST",
      data: payload,
    });
    callback(response);
    return response;
  } catch (error) {
    callback(null, error);
  }
});
