import { createSlice } from "@reduxjs/toolkit";
import {
  asignRide,
  fetchRides,
  getCSVFile,
  fetchAddress,
  fetchDriverList,
  updateRideStatus,
  updateDriverOfRide,
  fetchReassignRequest,
  fetchActiveRideDetail,
  sendNotificationToDriver,
  fetchLatLong,
  createScheduleRide,
} from "../action/rideActions";

const initialState = {
  isLoading: false,
  rides: [],
  addresses: [],
  driverList: [],
  reassignRequestList: [],
  activeRide: {},
  riderDetails: {},
  modelDescription: "",
};

export const rideSlice = createSlice({
  name: "Auth",
  initialState: initialState,
  reducers: {
    setActiveRide: (state, action) => {
      state.activeRide = action?.payload;
    },
    setModalDescription: (state, action) => {
      state.modelDescription = action?.payload;
    },
  },
  extraReducers: (builder) => {
    // fetch-rides
    builder.addCase(fetchRides.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRides.fulfilled, (state, action) => {
      const { data } = action?.payload ?? {};
      state.rides = data || [];
      state.isLoading = false;
    });
    builder.addCase(fetchRides.rejected, (state, action) => {
      state.isLoading = false;
    });

    // fetch-active-ride-detail
    builder.addCase(fetchActiveRideDetail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchActiveRideDetail.fulfilled, (state, action) => {
      const { data } = action?.payload ?? {};

      state.riderDetails = (data && data[0]) || {};
      state.activeRide = (data && data[0]) || {};
      state.isLoading = false;
    });
    builder.addCase(fetchActiveRideDetail.rejected, (state, action) => {
      state.isLoading = false;
    });

    // fetch-driver-list
    builder.addCase(fetchDriverList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDriverList.fulfilled, (state, action) => {
      const { data } = action?.payload ?? {};
      state.driverList = data || [];
      state.isLoading = false;
    });
    builder.addCase(fetchDriverList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // assign-ride
    builder.addCase(asignRide.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asignRide.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(asignRide.rejected, (state, action) => {
      state.isLoading = false;
    });

    // get-csv
    builder.addCase(getCSVFile.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCSVFile.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getCSVFile.rejected, (state, action) => {
      state.isLoading = false;
    });

    // update-ride-status
    builder.addCase(updateRideStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateRideStatus.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateRideStatus.rejected, (state, action) => {
      state.isLoading = false;
    });

    // update-ride-status
    builder.addCase(updateDriverOfRide.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateDriverOfRide.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateDriverOfRide.rejected, (state, action) => {
      state.isLoading = false;
    });
    // update-ride-status
    builder.addCase(sendNotificationToDriver.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sendNotificationToDriver.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(sendNotificationToDriver.rejected, (state, action) => {
      state.isLoading = false;
    });

    // fetch-reassign-request
    builder.addCase(fetchReassignRequest.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchReassignRequest.fulfilled, (state, action) => {
      const { data, success } = action?.payload ?? {};
      state.reassignRequestList = data ?? [];
      state.isLoading = false;
    });
    builder.addCase(fetchReassignRequest.rejected, (state, action) => {
      state.isLoading = false;
    });

    // fetch-address
    builder.addCase(fetchAddress.pending, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAddress.fulfilled, (state, action) => {
      const { response } = action?.payload ?? {};
      state.addresses = response?.predictions || [];
      state.isLoading = false;
    });
    builder.addCase(fetchAddress.rejected, (state, action) => {
      state.isLoading = false;
    });

    // fetch-lat-long
    builder.addCase(fetchLatLong.pending, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchLatLong.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchLatLong.rejected, (state, action) => {
      state.isLoading = false;
    });

    // create-schedule-ride
    builder.addCase(createScheduleRide.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createScheduleRide.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createScheduleRide.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { setActiveRide, setModalDescription } = rideSlice.actions;
export default rideSlice.reducer;
