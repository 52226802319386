import React from "react";
import qr_cab_png from "../Images/qr_cab.png";
import logout_png from "../Images/logout.png";
import { useNavigate } from "react-router-dom";
import { message, message as MESSAGE } from "antd";
import { useLocation } from "react-router-dom";
import pageRoutes from "../routes";

const Navbar = () => {
  const { pathname } = useLocation() ?? {};
  const navigate = useNavigate();
  const cursor = { cursor: "pointer" };

  const onHandleNavbar = (val) => {
    navigate(val);
  };
  const logOutPage = () => {
    localStorage.clear();
    MESSAGE.success("logged out successfully");
    navigate(pageRoutes?.login);
  };

  return (
    <div className="ct_h_173">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="ct_header_bg">
              <div className="ct_top_head">
                <div className="ct_login_logo">
                  <img src={qr_cab_png} alt="img/qr_cab.png" />
                </div>
                <div className="ct_logout_icon">
                  <a>
                    <img
                      src={logout_png}
                      onClick={logOutPage}
                      alt="img/logout.png"
                    />
                  </a>
                </div>
              </div>
              <div className="ct_navbar_main">
                <nav className="navbar navbar-expand-lg ">
                  <div className="container-fluid">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsibleNavbar"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                      className="collapse navbar-collapse"
                      id="collapsibleNavbar"
                    >
                      <ul className="navbar-nav">
                        <li
                          className="nav-item"
                          onClick={() => onHandleNavbar(pageRoutes?.dashboard)}
                        >
                          <a
                            className={
                              pathname == pageRoutes?.dashboard
                                ? "nav-link active"
                                : "nav-link"
                            }
                            style={cursor}
                          >
                            Dashboard
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => onHandleNavbar(pageRoutes?.rides1)}
                        >
                          <a
                            className={
                              pathname == pageRoutes?.rides1
                                ? "nav-link active"
                                : "nav-link"
                            }
                            style={cursor}
                          >
                            Rides
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => onHandleNavbar(pageRoutes?.reAssign)}
                        >
                          <a
                            className={
                              pathname == pageRoutes?.reAssign
                                ? "nav-link active"
                                : "nav-link"
                            }
                            style={cursor}
                          >
                            Reassign Rides
                          </a>
                        </li>
                        {/* <li
                          className="nav-item"
                          onClick={() => onHandleNavbar(pageRoutes?.rides)}
                        >
                          <a
                            className={
                              pathname == pageRoutes?.rides
                                ? "nav-link active"
                                : "nav-link"
                            }
                            style={cursor}
                          >
                            Rides
                          </a>
                        </li> */}
                        <li
                          className="nav-item"
                          onClick={() => onHandleNavbar(pageRoutes?.cabs)}
                        >
                          <a
                            className={
                              pathname == pageRoutes?.cabs
                                ? "nav-link active"
                                : "nav-link"
                            }
                            style={cursor}
                          >
                            Drivers
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => onHandleNavbar(pageRoutes?.users)}
                        >
                          <a
                            className={
                              pathname == pageRoutes?.users
                                ? "nav-link active"
                                : "nav-link"
                            }
                            style={cursor}
                          >
                            Users
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => onHandleNavbar(pageRoutes?.location)}
                        >
                          <a
                            className={
                              pathname == pageRoutes?.location
                                ? "nav-link active"
                                : "nav-link"
                            }
                            style={cursor}
                          >
                            Location
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => onHandleNavbar(pageRoutes?.category)}
                        >
                          <a
                            className={
                              pathname == pageRoutes?.category
                                ? "nav-link active"
                                : "nav-link"
                            }
                            style={cursor}
                          >
                            Category
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => onHandleNavbar(pageRoutes?.settings)}
                        >
                          <a
                            className={
                              [
                                pageRoutes?.settings,

                                pageRoutes?.emergencyNotification,
                                pageRoutes?.complaint,
                              ]?.includes(pathname)
                                ? "nav-link active"
                                : "nav-link"
                            }
                            style={cursor}
                          >
                            Settings
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
