import { API_REQUEST } from ".";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  all_rides,
  assign_ride,
  get_all_driver,
  getAllReassignRequestAPI,
  getCsvFilterAPI,
  getLatLong,
  ride_details,
  schedule_ride,
  send_notification,
  status_change,
  update_Driver,
  webGetLocationEndPointURL,
} from "../../Components/Config";

// fetch-rides
export const fetchRides = createAsyncThunk("fetch-rides", async () => {
  try {
    const response = await API_REQUEST({
      url: all_rides,
      method: "GET",
    });
    return response;
  } catch (error) {}
});

// fetch-active-ride-detail
export const fetchActiveRideDetail = createAsyncThunk(
  "fetch-active-ride-detail",
  async (props) => {
    try {
      const { payload } = props;
      const response = await API_REQUEST({
        url: ride_details,
        method: "POST",
        isSuccessToast: false,
        data: payload,
      });
      return response;
    } catch (error) {}
  }
);

// fetch-driver-list
export const fetchDriverList = createAsyncThunk(
  "fetch-driver-list",
  async () => {
    try {
      const response = await API_REQUEST({
        url: get_all_driver,
        method: "GET",
      });
      return response;
    } catch (error) {}
  }
);

// assign-ride
export const asignRide = createAsyncThunk("assign-ride", async (props) => {
  try {
    const { payload, callback } = props;
    const response = await API_REQUEST({
      url: assign_ride,
      method: "POST",
      data: payload,
    });
    callback(response);
    return response;
  } catch (error) {}
});

// get-csv
export const getCSVFile = createAsyncThunk("get-csv", async (props) => {
  const { payload, callback } = props;
  try {
    const response = await API_REQUEST({
      url: getCsvFilterAPI,
      method: "GET",
      params: payload,
    });
    callback(response);
    return response;
  } catch (error) {}
});

// update-ride-status
export const updateRideStatus = createAsyncThunk(
  "update-ride-status",
  async (props) => {
    try {
      const { payload, callback } = props;
      const response = await API_REQUEST({
        url: status_change,
        method: "POST",
        data: payload,
      });
      callback(response);
      return response;
    } catch (error) {}
  }
);

// update-driver
export const updateDriverOfRide = createAsyncThunk(
  "update-driver",
  async (props) => {
    try {
      const { payload, callback } = props;
      const response = await API_REQUEST({
        url: update_Driver,
        method: "POST",
        data: payload,
      });
      callback(response);
      return response;
    } catch (error) {}
  }
);

// send-notification-to-driver
export const sendNotificationToDriver = createAsyncThunk(
  "send-notification-to-driver",
  async (props) => {
    try {
      const { payload } = props;
      const response = await API_REQUEST({
        url: send_notification,
        method: "POST",
        data: payload,
      });
      return response;
    } catch (error) {}
  }
);

// fetch-reassign-request
export const fetchReassignRequest = createAsyncThunk(
  "fetch-reassign-request",
  async () => {
    try {
      const response = await API_REQUEST({
        url: getAllReassignRequestAPI,
        method: "GET",
      });
      return response;
    } catch (error) {}
  }
);
// fetch-address
export const fetchAddress = createAsyncThunk("fetch-address", async (props) => {
  try {
    const { payload, callback } = props;
    const response = await API_REQUEST({
      url: webGetLocationEndPointURL,
      method: "POST",
      isSuccessToast: false,
      isErrorToast: false,
      data: payload,
    });
    callback(response);
    return response;
  } catch (error) {}
});

// fetch-lat-long
export const fetchLatLong = createAsyncThunk("fetch-lat-long", async (props) => {
  try {
    const { payload, callback } = props;
    const response = await API_REQUEST({
      url: getLatLong,
      method: "GET",
      isSuccessToast: false,
      isErrorToast: false,
      params: payload,
    });
    callback(response);
    return response;
  } catch (error) {}
});

// create-schedule-ride
export const createScheduleRide = createAsyncThunk("create-schedule-ride", async (props) => {
  try {
    const { payload, callback } = props;
    const response = await API_REQUEST({
      url: schedule_ride,
      method: "POST",
      data: payload,
    });
    callback(response);
    return response;
  } catch (error) {}
});
