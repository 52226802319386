import { useEffect } from "react";
import pageRoutes from "../routes";
import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import { useNavigate } from "react-router-dom";
import CarLoader from "../Components/carLoader";
import { pipGetAccessToken } from "../utils/pip";
import { useDispatch, useSelector } from "react-redux";
import ReactPagination from "../Components/reactPagination";
import PaginationDropdown from "../Components/PaginationDropdown";
import { setModalDescription } from "../redux/reducers/rideReducer";
import {
  asignRide,
  fetchDriverList,
  fetchReassignRequest,
} from "../redux/action/rideActions";
import { Modal } from "antd";

function ReAssign() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { driverList, reassignRequestList, isLoading, modelDescription } =
    useSelector((state) => state.rideReducer);
  const [list, setList] = useState(reassignRequestList);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [listPerPages, setListPerPages] = useState(5);

  const handlePageClick = (data) => {
    setCurrentPage(data?.selected);
  };

  const paginatedStudent = reassignRequestList?.slice(
    currentPage * listPerPages,
    (currentPage + 1) * listPerPages
  );

  useEffect(() => {
    const token = pipGetAccessToken();
    if (token == null) {
      navigate(pageRoutes?.login);
    } else {
      dispatch(fetchReassignRequest());
      dispatch(fetchDriverList());
    }
  }, [dispatch]);

  const AssignToDriver = (driver, ride) => {
    const data = {
      driver_id: driver?.id,
      booking_id: ride?.id,
      user_id: ride?.user_id,
      name: ride?.name ?? "",
    };
    console.log({ data });

    const callback = (response) => {
      if (response.success) {
        dispatch(fetchReassignRequest());
        dispatch(fetchDriverList());
      }
    };
    dispatch(
      asignRide({
        payload: data,
        callback,
      })
    );
  };

  const showModalWithDescription = (description) => {
    dispatch(setModalDescription(description));
    setIsEditModalOpen(true);
  };

  if (isLoading) {
    return <CarLoader />;
  }
  return (
    <>
      <section className="ct_padd_in_100">
        <Navbar />

        <div className="container-fluid">
          {/* <SubNavbar /> */}

          <div className="ct_location_table table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>User Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Ride Detail</th>
                  <th>Previous Driver</th>
                  <th>Assigned Driver</th>
                  <th>Assign Driver</th>
                </tr>
              </thead>
              {paginatedStudent && paginatedStudent?.length != 0 ? (
                <tbody>
                  {paginatedStudent?.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <div className="ct_user_img_circle  d-flex align-items-center gap-2">
                          <img
                            src={
                              item?.user?.profile_image ?? "img/Group_1634.png"
                            }
                            alt=""
                          />
                          <h6 className="mb-0">{item?.user?.name}</h6>
                        </div>
                      </td>
                      <td>{item?.dateOfBooking}</td>
                      <td>{item?.time}</td>

                      <td>
                        <>
                          {`${item?.start} to ${item?.destination}`.slice(
                            0,
                            15
                          )}
                          ...{" "}
                          <span
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() =>
                              showModalWithDescription(
                                `${item?.start} to ${item?.destination}`
                              )
                            }
                          >
                            more
                          </span>
                        </>
                      </td>
                      <td>
                        {item?.assign_by?.length > 0
                          ? item?.assign_by[0]?.name
                          : "-"}
                      </td>
                      <td>
                        {item?.driver_data?.length > 0
                          ? item?.driver_data[0]?.name
                          : "-"}
                      </td>

                      <td>
                        <select
                          className="form-control"
                          onChange={(e) =>
                            AssignToDriver(JSON.parse(e.target.value), item)
                          }
                        >
                          <option value="" disabled selected>
                            Assign To
                          </option>

                          {driverList?.map((driver) => (
                            <option value={JSON.stringify(driver)}>
                              {driver?.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <h2 className="ct_fs_24 text-center py-4">No Record Found</h2>
              )}
            </table>
          </div>
          {paginatedStudent?.length != 0 && (
            <>
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div>
                  <PaginationDropdown
                    onChange={(val) => {
                      setListPerPages(val);
                      setCurrentPage(0);
                    }}
                  />
                </div>
                <div>
                  <ReactPagination
                    pageCount={Math.ceil(
                      reassignRequestList?.length / listPerPages
                    )}
                    onPageChange={handlePageClick}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </section>

      {/* Modal for ride detail*/}

      <Modal
        className="ct_ant_modal_xl"
        title={``}
        centered={true}
        open={isEditModalOpen}
        onCancel={() => {
          setIsEditModalOpen(false);
        }}
        footer={null}
      >
        <div className="mt-4">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <h5 className="mb-4 text-center">{modelDescription ?? ""}</h5>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ReAssign;
