import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { message as MESSAGE } from "antd";
import pageRoutes from '../../routes';
import { pipSetAccessToken } from '../../utils/pip';
export const configJSON = require("../Config");

const VerifyRide = () => {
    const [otp, setOtp] = useState();
    const [phone_number, setPhone_number] = useState();
    const [enterotp, setEnterotp] = useState();
    const navigate = useNavigate()

    const onHanldeCheckOtp = () => {
        const data = {
            verify_otp: otp,
            phone_no: phone_number
        }
        axios({
            method: "post",
            url: configJSON.webBaseUrl + configJSON.webOtpCheckEndPointURL,
            data: data
        })
            .then((res) => {
                setOtp("")
                console.log(res?.data, "res?.data")
                if (res?.data?.success == true) {
                    bookingNotification(res?.data?.user_id)
                    MESSAGE.success(res?.data?.message);
                    pipSetAccessToken(res?.data?.JWT_token)
                    navigate(pageRoutes?.allBooking)
                } else {
                    MESSAGE.error(res?.data?.message)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const bookingNotification = (val) => {
        if (val) {
            const driver_id = JSON.parse(localStorage.getItem("driver_id"))
            const data = {
                sender_id: val,
                reciver_id: parseInt(driver_id),
                notification_type: "booking",
                reciver_type: "Driver"
            }
            axios({
                method: "post",
                url: configJSON.webBaseUrl + configJSON.send_notification,
                data: data
            })
                .then((res) => {
                    console.log({ res })
                })
                .catch((err) => {
                    console.log({ err })
                })
        }
    }

    useEffect(() => {
        const otp_enter = JSON.parse(localStorage.getItem('otp'))
        const user_details = JSON.parse(localStorage.getItem('user_details'))
        setPhone_number(user_details);
        setEnterotp(otp_enter)
    }, [])

    return (
        <div>
            <header>
                <div className="ct_navigation justify-content-center">
                    <div className="ct_logo" onClick={() => navigate(pageRoutes?.bookRide)}>
                        <img src="img/logo.png" alt="img" />
                    </div>
                </div>
            </header>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="ct_reutn_booking_main ct_center_positioin">
                                <h4>Return Booking</h4>
                                <form>
                                    <div className="ct_opt_div">
                                        <div className="ct_otp_input">
                                            <OtpInput
                                                className="form-control"
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={4}
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props} />}
                                            />
                                        </div>
                                        <p className="text-center mt-4">Enter {enterotp} as verfication code.</p>
                                        <div className="mt-5">
                                            <button onClick={onHanldeCheckOtp} type="button" className="ct_custom_btn">Confirm Ride</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default VerifyRide
